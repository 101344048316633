import supabase from "@/utils/supabase";

export default {
  name: "Logout",
  async created() {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      console.log("logout");
      this.$router.push({ path: "/login" });
    }
  },
};
